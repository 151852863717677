/*
 * @Descripttion: *
 * @version: *
 * @Author: Mingkit
 * @Date: 2021-01-08 10:26:01
 * @LastEditors: Mingkit
 * @LastEditTime: 2021-01-13 18:20:39
 */
import ComboAgent from './ComboAgent'
import ComboApp from './ComboApp'
import ComboForm from './ComboForm'
export default {
  name: 'AdminCombo',
  components: {
    ComboAgent,
    ComboApp,
    ComboForm
  },
  data () {
    return {
      activeName: 'agent',
      editName: '',
      editType: 1,
      type: 'add'
    }
  },
  methods: {
    add (type) {
      this.type = 'add'
      this.editName = type === 'agent' ? '代理商' : '客户'
      this.editType = type === 'agent' ? 1 : 2
      this.$refs.comboForm.show()
    },
    emitFunction () {
      this.editType === 1 ? this.$refs.comboAgent.tableSearch(true) : this.$refs.comboApp.tableSearch(true)
    }
  }
}
