/*
 * @Descripttion: *
 * @version: *
 * @Author: Mingkit
 * @Date: 2021-01-08 10:34:39
 * @LastEditors: Mingkit
 * @LastEditTime: 2021-01-14 17:49:27
 */
import { moneyFormat } from '@/plugins/utils'
export default {
  name: 'AdminComboAgent',
  methods: {
    async edit (id) {
      this.$parent.type = 'edit'
      console.log(this.$parent)
      this.$parent.editName = '代理商'
      this.$parent.editType = 1
      const data = await this.$wPost('/admin/combo/get_combo.do', { id: id })
      if (data !== false) {
        console.log(data)
        data.date = [data.effect_start_date, data.effect_end_date]
        data.price = data.price / 100
        data.original_price = data.original_price / 100
        await this.$parent.$refs.comboForm.updatedForm(data)
        this.$parent.$refs.comboForm.show()
      }
    },
    tableSearch (reset, sort) {
      // if (!sort) {
      //   this.queryForm.field_type = ''
      //   this.queryForm.sort_type = ''
      // }
      if (reset) {
        this.$refs['w-table'].resetList()
      } else {
        this.$refs['w-table'].getList()
      }
    },
    moneyFormat,
    async updateStatus (row) {
      console.log(row)
      let data
      switch (row.status) {
        case 1:
        case 2:
          data = await this.$wPost('/admin/combo/update_status.do', {
            id: row.id,
            status: 3
          })
          if (data === true) {
            this.$wToast.success('已停用')
            this.tableSearch(true)
          }
          break
        case 3:
          data = await this.$wPost('/admin/combo/update_status.do', {
            id: row.id,
            status: 2
          })
          if (data === true) {
            this.$wToast.success('已启用')
            this.tableSearch(true)
          }
          break
        default:
          break
      }
    },
    // 转化套餐周期单位
    comboCycle (val) {
      if (!val || val === '-') {
        return val
      }

      if (val % 12 === 0) {
        return val / 12 + '年'
      } else {
        return val + '月'
      }
    }
  }
}
