/*
 * @Descripttion: *
 * @version: *
 * @Author: Mingkit
 * @Date: 2021-01-08 10:34:43
 * @LastEditors: Mingkit
 * @LastEditTime: 2021-01-08 10:35:57
 */
import ComboAgent from './ComboAgent.vue'
export default ComboAgent
