/*
 * @Descripttion: *
 * @version: *
 * @Author: Mingkit
 * @Date: 2021-01-13 16:48:03
 * @LastEditors: Mingkit
 * @LastEditTime: 2021-01-14 17:47:39
 */
class HandleData {
  constructor (payload) {
    this.payload = payload
  }

  addAgentCombo () {
    const newPayload = {
      storage: Number(this.payload.storage),
      price: Number(this.payload.price) * 100,
      original_price: Number(this.payload.original_price) * 100,
      period_month: this.payload.period_month,
      effect_start_date: this.payload.date[0],
      effect_end_date: this.payload.date[1]
    }
    return newPayload
  }

  editAgentCombo () {
    const newPayload = {
      id: Number(this.payload.id),
      storage: Number(this.payload.storage),
      price: Number(this.payload.price) * 100,
      original_price: Number(this.payload.original_price) * 100,
      period_month: this.payload.period_month,
      effect_start_date: this.payload.date[0],
      effect_end_date: this.payload.date[1]
    }
    return newPayload
  }

  addAppCombo () {
    const newPayload = {
      storage: Number(this.payload.storage),
      price: Number(this.payload.price) * 100,
      original_price: Number(this.payload.original_price) * 100,
      period_month: this.payload.period_month,
      effect_start_date: this.payload.date[0],
      effect_end_date: this.payload.date[1]
    }
    return newPayload
  }

  editAppCombo () {
    const newPayload = {
      id: Number(this.payload.id),
      storage: Number(this.payload.storage),
      price: Number(this.payload.price) * 100,
      original_price: Number(this.payload.original_price) * 100,
      period_month: this.payload.period_month,
      effect_start_date: this.payload.date[0],
      effect_end_date: this.payload.date[1]
    }
    return newPayload
  }
}

export default HandleData
