/*
 * @Descripttion: *
 * @version: *
 * @Author: Mingkit
 * @Date: 2021-01-11 09:48:09
 * @LastEditors: Mingkit
 * @LastEditTime: 2021-01-13 18:42:06
 */
import { wVerifyNum } from '@/plugins/verifier'
import HandleData from '@/service/Combo'
export default {
  name: 'AgentComboForm',
  data () {
    return {
      WComboAddShow: false,
      comboList: [{
        name: '1年',
        id: 12
      }, {
        name: '1月',
        id: 1
      }],
      rules: {
        storage: [{
          validator (rule, val, res) {
            if (val === '') {
              res('请填写数据存储额度')
            }
            if (!wVerifyNum(val)) {
              res('请填写正整数')
            }
            if (val.length > 12) {
              res('数据存储额度不能超过1000亿')
            }
            res()
          },
          trigger: 'blur',
          required: true
        }],
        price: [{
          validator (rule, val, res) {
            if (val === '') {
              res('请填写售卖价格')
            }
            if (!wVerifyNum(val)) {
              res('请填写正整数')
            }
            if (val.length > 12) {
              res('售卖价格不能超过1000亿')
            }
            res()
          },
          trigger: 'blur',
          required: true
        }],
        original_price: [{
          validator (rule, val, res) {
            if (val === '') {
              res('请填写原价')
            }
            if (!wVerifyNum(val)) {
              res('请填写正整数')
            }
            if (val.length > 12) {
              res('原价不能超过1000亿')
            }
            res()
          },
          trigger: 'blur',
          required: true
        }],
        period_month: [{
          trigger: 'change',
          required: true
        }],
        date: [{
          validator (rule, val, res) {
            if (val.length < 2) {
              res('请填写生效时间')
            }
            res()
          },
          trigger: 'blur',
          required: true
        }]
      },
      showCancel: true,
      form: {
        id: '',
        storage: '',
        price: '',
        original_price: '',
        period_month: 12,
        effect_start_date: '',
        effect_end_date: '',
        date: []
      }
    }
  },
  // watch: {
  //   date (val, old) {
  //     if (val) {
  //       this.$set(this.form, 'effect_start_date', val[0])
  //       this.$set(this.form, 'effect_end_date', val[1])
  //     }
  //   }
  // },
  methods: {
    close () {
      this.$refs.form.clearValidate()
      this.updatedForm({
        id: '',
        storage: '',
        price: '',
        original_price: '',
        period_month: 12,
        effect_start_date: '',
        effect_end_date: '',
        date: []
      })
      if (this.showCancel) {
        this.$wToast.warning(`已取消${this.type === 'add' ? '新增' : '修改'}`)
      }
    },
    cancel () {
      this.WComboAddShow = false
    },
    submit () {
      console.log(this.editType)
      this.$refs.form.validate(v => {
        if (v) {
          if (this.isAdd()) {
            this.editType === 1 ? this.addAgentSubmit() : this.addAppSubmit()
          } else {
            this.editType === 1 ? this.editAgentSubmit() : this.editAppSubmit()
          }
          this.$refs.submitButton.updatedLoading(false)
        } else {
          this.$refs.submitButton.updatedLoading(false)
        }
      })
    },
    async addAgentSubmit () {
      const newPayload = new HandleData(this.form).addAgentCombo()
      const data = await this.$wPost('/admin/combo/add_agent.do', newPayload)
      if (data === true) {
        this.$wToast.success('新建套餐成功')
        this.showCancel = false
        this.hide()
        this.$emit('add')
      }
      const timer = setTimeout(() => {
        clearTimeout(timer)
        this.showCancel = true
      }, 500)
    },
    async editAgentSubmit () {
      const newPayload = new HandleData(this.form).editAgentCombo()
      const data = await this.$wPost('/admin/combo/update_agent.do', newPayload)
      if (data === true) {
        this.$wToast.success('修改套餐成功')
        this.showCancel = false
        this.hide()
        this.$emit('add')
      }
      const timer = setTimeout(() => {
        clearTimeout(timer)
        this.showCancel = true
      }, 500)
    },
    async addAppSubmit () {
      const newPayload = new HandleData(this.form).addAppCombo()
      const data = await this.$wPost('/admin/combo/add_custom.do', newPayload)
      if (data === true) {
        this.$wToast.success('修改套餐成功')
        this.showCancel = false
        this.hide()
        this.$emit('add')
      }
      const timer = setTimeout(() => {
        clearTimeout(timer)
        this.showCancel = true
      }, 500)
    },
    async editAppSubmit () {
      const newPayload = new HandleData(this.form).editAppCombo()
      const data = await this.$wPost('/admin/combo/update_custom.do', newPayload)
      if (data === true) {
        this.$wToast.success('修改套餐成功')
        this.showCancel = false
        this.hide()
        this.$emit('add')
      }
      const timer = setTimeout(() => {
        clearTimeout(timer)
        this.showCancel = true
      }, 500)
    },
    async updatedForm (payload) {
      for (const props in payload) {
        await this.$set(this.form, props, payload[props])
      }
    },
    isAdd () {
      if (this.type === 'add') {
        return true
      } else {
        return false
      }
    },
    show () {
      this.WComboAddShow = true
    },
    hide () {
      this.WComboAddShow = false
    }
  },
  props: {
    type: {
      type: String,
      default: 'add'
    },
    editName: {
      type: String
    },
    editType: {
      type: Number
    }
  }
}
